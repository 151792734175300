import {Link as ChakraLink, Stack, Tooltip} from "@chakra-ui/react";
import {motion} from "framer-motion";
import React from "react";
import {ApplePodcastsIcon, CastroIcon, OvercastIcon, RssIcon, SpotifyIcon} from "./Layout/Icons";

const Link = ({href, title, children, ...props}) => (
	<motion.div whileHover={{scale: 1.1}}>
		<Tooltip label={title} hasArrow={true} placement={"top"}>
			<ChakraLink href={href} target="_blank" {...props}>
				{children}
			</ChakraLink>
		</Tooltip>
	</motion.div>
);

export const Platforms = () => {
	return (
		<Stack direction={"row"} spacing={3}>
			<Link href="https://podcasts.apple.com/us/podcast/koen-en-elmar-show/id1530839292?mt=2&app=podcast" title={"Apple Podcasts"}>
				<ApplePodcastsIcon />
			</Link>
			<Link href={"https://open.spotify.com/show/7hsV7DRNtC2OSwKsQIzLUM"} title={"Spotify"}>
				<SpotifyIcon />
			</Link>
			<Link href={"https://overcast.fm/itunes1530839292"} title={"Overcast"}>
				<OvercastIcon />
			</Link>
			<Link href={"https://castro.fm/itunes/1530839292"} title={"Castro"}>
				<CastroIcon />
			</Link>
			<Link href="/podcast/feed.xml" title={"RSS"}>
				<RssIcon />
			</Link>
		</Stack>
	);
};

export default Platforms;