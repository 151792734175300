import {BoxProps, Heading, HStack, Stack, Text, Badge} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import {Episode} from "../models/episode";
import Container from "./Layout/Container";

type Props = BoxProps & {episode: Episode, linkToEpisode?: boolean};

const EpisodeListItem: React.FC<Props> = ({episode, ...props}) => {
	const {title, date, duration, shortText, guests = []} = episode;

	return (
		<Container {...props}>
			<Stack spacing={5}>
				<Stack spacing={2} width={"100%"}>
					<Heading size={"md"}>{title}</Heading>

					<HStack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
						<HStack direction={"row"} divider={<Text px={2}>·</Text>}>
							<Text fontSize={"sm"} textTransform={"uppercase"}>{moment(date).format("LL")}</Text>
							<Text fontSize={"sm"} textTransform={"uppercase"}>{Math.ceil(moment.duration(duration, "s").asMinutes())} minuten</Text>
							{guests.length > 0 && (
								<Badge colorScheme={"orange"}>met gast</Badge>
							)}
						</HStack>
					</HStack>
				</Stack>
				<Text>{shortText}</Text>
			</Stack>
		</Container>
	);
};

export default EpisodeListItem;