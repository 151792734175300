import {Box, BoxProps} from "@chakra-ui/react";
import React from "react";

export const Left: React.FC<BoxProps> = ({...props}) => {
	return (
		<Box flex={2} {...props}>
			{props.children}
		</Box>
	);
};

export const Right: React.FC<BoxProps> = ({...props}) => {
	return (
		<Box flex={3} {...props}>
			{props.children}
		</Box>
	);
};