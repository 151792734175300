import {Box, BoxProps, Flex, Spinner} from "@chakra-ui/react";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import React, {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

const Markdown: React.FC<BoxProps & {src: string}> = ({src, ...props}) => {
	const [md, setMd] = useState<string>();

	useEffect(() => {
		fetch(src)
			.then(res => res.text())
			.then(res => setMd(res));
	}, [src]);


	if (!md) {
		return (
			<Flex justifyContent={"center"}>
				<Spinner />
			</Flex>
		);
	}

	return (
		<Box>
			<ReactMarkdown components={ChakraUIRenderer()} plugins={[gfm]} linkTarget={"_blank"}>{md}</ReactMarkdown>
		</Box>
	);
};

export default Markdown;