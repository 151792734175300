import {AspectRatio, Box, Link, Stack, StackProps, Text, useBreakpointValue} from "@chakra-ui/react";
import {motion} from "framer-motion";
import React from "react";
import {NavLink} from "react-router-dom";
import AvatarElmar from "../assets/images/elmar.png";
import AvatarKoen from "../assets/images/koen.png";
import Artwork from "./Layout/Artwork";
import Host from "./Layout/Host";
import Headings from "./Layout/PageHeading";
import SocialButton from "./Layout/SocialButton";
import Platforms from "./Platforms";

const Sidebar: React.FC<StackProps> = ({...props}) => {
	const isMobile = useBreakpointValue([true, true, false]);

	return (
		<Stack spacing={10} px={5} {...props}>

			{!isMobile && (
				<AspectRatio ratio={1}>
					<Box as={motion.div} whileHover={{scale: 1.05}} whileTap={{scale: .95}}>
						<NavLink to="/">
							<Artwork borderRadius={10} />
						</NavLink>
					</Box>
				</AspectRatio>
			)}

			<Stack spacing={2}>
				<Text textTransform={"uppercase"}>Gehost door <strong>Koen Brouwer</strong> en <strong>Elmar Beckmann</strong>.</Text>
				<Text>Koen en Elmar zijn allebei software-ontwikkelaars en spreken in deze podcast over onderwerpen die aan bod komen tijdens hun werk.</Text>
			</Stack>

			<Stack spacing={2}>
				<Headings.Sidebar>Abonneren</Headings.Sidebar>
				<Platforms />
			</Stack>

			<Stack spacing={2}>
				<Headings.Sidebar>Hosts</Headings.Sidebar>
				<Host name={"Koen Brouwer"} social={{type: "twitter", handle: "KoenBrouwer"}} avatar={AvatarKoen} />
				<Host name={"Elmar Beckmann"} social={{type: "twitter", handle: "elmardotdev"}} avatar={AvatarElmar} />
			</Stack>

			<Stack spacing={2}>
				<Headings.Sidebar>Support</Headings.Sidebar>
				<Stack>
					<Text fontSize={"sm"}>
						Vind je onze show leuk?
					</Text>
					<Box>
						<SocialButton social={{type: "patreon", handle: "6817200"}} />
					</Box>
				</Stack>
			</Stack>

			<Stack spacing={2}>
				<Text fontSize={"sm"}>
					Podcast gehost op <Link href={"https://soundcloud.com/koenenelmarshow"}>SoundCloud</Link>. Website gehost op <Link href={"https://render.com"}>Render</Link>.
				</Text>
				<Text fontSize={"sm"}>
					Copyright &copy; 2020-{(new Date()).getFullYear()} Koen Brouwer en Elmar Beckmann.
				</Text>
			</Stack>

			<Stack spacing={2}>
				<Box>
					<SocialButton social={{type: "twitter", handle: "KoenEnElmarShow"}} />
				</Box>
			</Stack>
		</Stack>
	);
};

export default Sidebar;