import {Avatar, Box, Stack, StackProps, Text} from "@chakra-ui/react";
import React from "react";
import {Social} from "../../models/episode";
import SocialButton from "./SocialButton";

type HostProps = {
	avatar: string,
	name: string,
	social: Social,
}

const Host: React.FC<StackProps & HostProps> = ({avatar, name, social, ...props}) => {
	return (
		<Stack spacing={4} direction={"row"} alignItems={"center"} {...props}>
			<Box>
				<Avatar size={"lg"} variant={"square"} src={avatar} name={name} />
			</Box>
			<Stack spacing={1}>
				<Text fontWeight={"bold"} fontSize={"md"}>{name}</Text>
				<SocialButton social={social} />
			</Stack>
		</Stack>
	);
};

export default Host;