import {Box, HStack, Text} from "@chakra-ui/react";
import React from "react";
import {MdArrowBack} from "react-icons/all";
import {NavLink} from "react-router-dom";
import {Routes} from "../../utils/Routes";

const BackButton: React.FC<{to?: string, label?: string}> = ({to, label}) => {
	return (
		<HStack mb={10} as={NavLink} to={to || Routes.Home} _hover={{textDecoration: "underline"}}>
			<Box as={MdArrowBack} size={"16px"} mr={1} />
			<Text fontSize={"sm"}>{label || "Terug naar hoofdpagina"}</Text>
		</HStack>
	);
};

export default BackButton;