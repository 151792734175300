import {Box, Divider, HStack, Link, Stack, Text, Wrap, WrapItem} from "@chakra-ui/react";
import prettyBytes from "pretty-bytes";
import React from "react";
import {MdArrowBack, MdArrowForward} from "react-icons/all";
import {NavLink, Redirect, useParams} from "react-router-dom";
import episodes from "../data/episodes";
import {Routes} from "../utils/Routes";
import EpisodeListItem from "./EpisodeListItem";
import BackButton from "./Layout/BackButton";
import Container from "./Layout/Container";
import Host from "./Layout/Host";
import Markdown from "./Layout/Markdown";
import Headings from "./Layout/PageHeading";

const EpisodePage = () => {
		const {guid} = useParams<any>();
		const episode = episodes.find(e => e.guid === guid);

		if (!episode) {
			return <Redirect to={Routes.NotFound} />;
		}

		const previousEpisode = episodes.find(e => e.id === episode.id - 1);
		const nextEpisode = episodes.find(e => e.id === episode.id + 1);
		const {audioUrl, size} = episode;

		return (
			<Container>
				<BackButton />

				<Stack spacing={10} width={"100%"}>
					<EpisodeListItem episode={episode} />

					{episode.guests && episode.guests.length >= 1 && (
						<Stack spacing={2}>
							<Headings.Page>{episode.guests.length === 1 ? "Gasthost" : "Gasthosts"}:</Headings.Page>
							<Wrap spacing={2}>
								{episode.guests.map((g, i) => (
									<WrapItem pr={5} key={i}>
										<Host name={g.name} social={g.social} avatar={"/images/" + g.avatar} />
									</WrapItem>
								))}
							</Wrap>
						</Stack>
					)}

					<Stack spacing={2}>
						<Headings.Page>Luister deze aflevering:</Headings.Page>
						<Box width={"100%"} bg={"black"} display={"flex"} justifyContent={"flex-start"}>
							<audio controls style={{width: "100%"}} src={audioUrl} autoPlay={false} preload={"none"} />
						</Box>
						<Link href={audioUrl} target={"_blank"} download>Download MP3: ({prettyBytes(size)})</Link>
					</Stack>

					<Divider />

					<Stack spacing={2}>
						<Headings.Page>Shownotes:</Headings.Page>
						<Markdown src={"/shownotes/" + guid + ".md"} />
					</Stack>

					<HStack justifyContent={"space-between"} alignItems={"center"}>
						{previousEpisode ? (
							<HStack as={NavLink} to={Routes.Episode(previousEpisode.guid)} _hover={{textDecoration: "underline"}}>
								<Box as={MdArrowBack} size={"16px"} mr={1} />
								<Text fontSize={"sm"}>Vorige aflevering</Text>
							</HStack>
						) : <Box />}
						{nextEpisode ? (
							<HStack as={NavLink} to={Routes.Episode(nextEpisode.guid)} _hover={{textDecoration: "underline"}}>
								<Text fontSize={"sm"}>Volgende aflevering</Text>
								<Box as={MdArrowForward} size={"16px"} ml={1} />
							</HStack>
						) : <Box />}
					</HStack>
				</Stack>
			</Container>
		);
	}
;

export default EpisodePage;