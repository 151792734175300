import {Box, Flex, Stack} from "@chakra-ui/react";
import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import EpisodeList from "./components/EpisodeList";
import EpisodePage from "./components/EpisodePage";
import Header from "./components/Header";
import Container from "./components/Layout/Container";
import {Left, Right} from "./components/Layout/LeftRight";
import NotFound from "./components/NotFound";
import Sidebar from "./components/Sidebar";
import episodes from "./data/episodes";

const App = () => {
	return (
		<Router>
			<Stack spacing={0}>
				<Header />

				<Box>
					<Flex as={Container} direction={["column", "column", "row"]} alignItems={"flex-start"}>
						<Left bg={"gray.100"}>
							<Sidebar p={8} mt={[0, 0, -180]} />
							<Box bgGradient="linear(to-b, gray.100, white)" h={"50px"} display={["none", "none", "block"]} />
						</Left>
						<Right>
							<Box p={10}>
								<Switch>
									<Route exact path={"/"}>
										<EpisodeList episodes={episodes} />
									</Route>
									<Route path={"/episode/:guid"} component={EpisodePage} />
									<Route component={NotFound} />
								</Switch>
							</Box>
						</Right>
					</Flex>
				</Box>
			</Stack>
		</Router>
	);
};

export default App;
