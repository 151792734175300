import {Box, BoxProps, useToken} from "@chakra-ui/react";
import React from "react";

const Container: React.FC<BoxProps> = ({...props}) => {
	const containerWidth = useToken("container", "lg");

	return (
		<Box maxWidth={containerWidth} margin={"0 auto"} p={0} {...props} />
	);
};

export default Container;