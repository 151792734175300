import {Box, BoxProps, Image} from "@chakra-ui/react";
import React from "react";
import artWork from "../../assets/images/artwork.png";

const Artwork: React.FC<BoxProps> = ({...props}) => {
	return (
		<Box overflow={"hidden"} {...props}>
			<Image src={artWork} maxW={"100%"} minW={"50px"} />
		</Box>
	);
};

export default Artwork;