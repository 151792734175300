import {Heading, Stack, Text, Box} from "@chakra-ui/react";
import React from "react";
import {MdArrowBack} from "react-icons/all";
import {Routes} from "../utils/Routes";
import Container from "./Layout/Container";
import { NavLink } from "react-router-dom";

const NotFound = () => {
	return (
		<Container>
			<Stack spacing={5}>
				<Stack spacing={0}>
					<Heading size={"2xl"} fontSize={"5em"} color={"primary.300"} maxWidth={450}>404</Heading>
					<Heading size={"xl"} maxWidth={450}>Pagina niet gevonden</Heading>
				</Stack>
				<Stack spacing={10}>
					<Text fontSize={"sm"}>
						De pagina niet je zoekt kan niet worden gevonden.
					</Text>
					<NavLink to={Routes.Home}>
						<Stack direction={"row"} alignItems={"center"}>
							<Box as={MdArrowBack} w={"16px"} h={"16px"} mr={2} />
							<Text fontSize={"sm"}>Terug naar de hoofdpagina</Text>
						</Stack>
					</NavLink>
				</Stack>
			</Stack>
		</Container>
	);
};

export default NotFound;