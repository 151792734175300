import {Stack, StackProps} from "@chakra-ui/react";
import {motion} from "framer-motion";
import React from "react";
import {NavLink} from "react-router-dom";
import {Episode} from "../models/episode";
import {Routes} from "../utils/Routes";
import EpisodeListItem from "./EpisodeListItem";
import Headings from "./Layout/PageHeading";

const EpisodeList: React.FC<StackProps & {episodes: Episode[]}> = ({episodes, ...props}) => {
	return (
		<Stack spacing={14} {...props}>
			<Headings.Page>Laatste afleveringen:</Headings.Page>

			{episodes && episodes.sort((a, b) => a.date >= b.date ? -1 : 1).map((e, i) => {
				// Todo: add pagination at some point
				return (
					<NavLink to={Routes.Episode(e.guid)} key={i}>
						<motion.div whileHover={{scale: 1.05}}>
							<EpisodeListItem episode={e} />
						</motion.div>
					</NavLink>
				);
			})}
		</Stack>
	);
};

export default EpisodeList;