import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/nl";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

moment.locale("nl");

const theme = extendTheme({
	container: {
		lg: 1200,
	},
	colors: {
		gray: {
			100: "#F2F2F2",
			200: "#D9D9D9",
			300: "#BFBFBF",
			400: "#A6A6A6",
			500: "#8C8C8C",
			600: "#737373",
			700: "#595959",
			800: "#404040",
			900: "#262626",
		},
		patreon: {
			100: "#FDCEB5",
			200: "#F7AE88",
			300: "#F18F5A",
			400: "#EC6F2C",
			500: "#D35613",
			600: "#A5430D",
			700: "#762F07",
			800: "#481B01",
			900: "#1E0700",
		},
	},
	fonts: {
		body: "Lato, sans-serif",
		heading: "Nunito Sans, serif",
		mono: "Menlo, monospace",
	},
});

ReactDOM.render(
	<React.StrictMode>
		<ChakraProvider theme={theme}>
			<App />
		</ChakraProvider>
	</React.StrictMode>,
	document.getElementById("root"),
);