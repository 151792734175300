import {Button} from "@chakra-ui/react";
import React from "react";
import {FaLinkedin, FaPatreon, FaTwitter} from "react-icons/all";
import {Social} from "../../models/episode";

const goTwitter = (handle: string) => {
	return `https://twitter.com/${handle}`;
};
const goLinkedIn = (handle: string) => {
	return `https://linkedin.com/in/${handle}/`;
};
const goPatreon = (handle: string) => {
	return `https://www.patreon.com/bePatron?u=${handle}`;
};

const SocialButton: React.FC<{social: Social}> = ({social}) => {
	const {handle} = social;

	if (social.type === "twitter") {
		return (
			<Button as={"a"} size={"xs"} variant={"outline"} colorScheme={"twitter"} leftIcon={<FaTwitter />} href={goTwitter(handle)} target={"_blank"}>
				Volg @{handle}
			</Button>
		);
	}

	if(social.type === "linkedin"){
		return (
			<Button as={"a"} size={"xs"} variant={"outline"} colorScheme={"linkedin"} leftIcon={<FaLinkedin />} href={goLinkedIn(handle)} target={"_blank"}>
				Volg @{handle}
			</Button>
		);
	}

	if(social.type === "patreon"){
		return (
			<Button as={"a"} size={"md"} variant={"solid"} colorScheme={"patreon"} leftIcon={<FaPatreon />} href={goPatreon(handle)} target={"_blank"}>
				Steun via Patreon
			</Button>
		);
	}

	return null;
};

export default SocialButton;