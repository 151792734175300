import {Box} from "@chakra-ui/react";
import React from "react";
import {ReactComponent as ApplePodcastsSvg} from "../../assets/images/apple-podcasts.svg";
import {ReactComponent as OvercastSvg} from "../../assets/images/overcast.svg";
import {ReactComponent as CastroSvg} from "../../assets/images/castro.svg";
import {ReactComponent as SpotifySvg} from "../../assets/images/spotify.svg";
import {ReactComponent as RssSvg} from "../../assets/images/rss.svg";

export const createIcon = (Svg: React.FC) => (
	<Box w={"36px"} h={"36px"}>
		<Svg />
	</Box>
)

export const OvercastIcon = () => createIcon(OvercastSvg);
export const CastroIcon = () => createIcon(CastroSvg);
export const SpotifyIcon = () => createIcon(SpotifySvg);
export const ApplePodcastsIcon = () => createIcon(ApplePodcastsSvg);
export const RssIcon = () => createIcon(RssSvg);