import {Heading, HeadingProps} from "@chakra-ui/react";
import React from "react";

const Page: React.FC<HeadingProps> = ({...props}) => (
	<Heading textTransform={"uppercase"} size={"md"} fontWeight={"bold"} {...props} />
);

const Sidebar: React.FC<HeadingProps> = ({...props}) => (
	<Heading textTransform={"uppercase"} size={"sm"} {...props} />
);

const Headings = {
	Page, Sidebar
};

export default Headings;