import moment from "moment";
import {Episode, Social} from "../models/episode";
import episodesData from "../assets/episodes.json";

const episodes: Episode[] = episodesData.map(e => ({
	...e,
	date: moment(e.date).toDate(),
	guests: e.guests?.map(g => ({
		...g,
		social: {
			...g.social,
			type: g.social.type as Social["type"]
		}
	}))
}));

export default episodes;