import {Box, Flex, Heading, Stack, StackProps, Text, useBreakpointValue} from "@chakra-ui/react";
import React from "react";
import {NavLink} from "react-router-dom";
import Artwork from "./Layout/Artwork";
import Container from "./Layout/Container";
import {Left, Right} from "./Layout/LeftRight";

const Header: React.FC<StackProps> = ({...props}) => {
	const isMobile = useBreakpointValue([true, true, false]);

	return isMobile ? (
		<Box bg={"gray.900"}>
			<Container>
				<Flex justifyContent={"center"} p={5}>
					<NavLink to="/">
						<Artwork maxWidth={300} borderRadius={10} />
					</NavLink>
				</Flex>
			</Container>
		</Box>
	) : (
		<Box bg={"gray.900"}>
			<Container>
				<Stack direction={"row"} spacing={0} alignItems={"center"} {...props}>
					<Left />
					<Right data-id={"checkHeight"}>
						<Box p={10}>
							<Heading color={"white"} size={"xl"}> Koen en Elmar show</Heading>
							<Text color={"white"} mb={4}>
								Koen en Elmar zijn allebei software-ontwikkelaars en spreken in deze podcast over onderwerpen die aan bod komen tijdens hun werk.
							</Text>
						</Box>
					</Right>
				</Stack>
			</Container>
		</Box>
	);
};

export default Header;